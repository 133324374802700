import moment from "moment";
import { formatNumber } from "../../../../function/numberFormat";
import storeFunction from "./storeFunction";

const wrongValidData = {
  manHour: 0,
  manHourMinute: 0,
  manHourString: "00:00",
  totalWorkTime: 0,
  totalWorkTimeMinute: 0,
  totalWorkTimeString: "00:00",
};

export const calculateManHour = (data, attendanceInit = {}) => {
  console.log("INPUT MANHOUR", data);
  let init = { ...data };
  //ถูก reset ค่าใหม่ตรงนี้
  init.manHour = 0;
  init.manHourMinute = 0;
  init.manHourString = "00:00";
  init.totalWorkTime = 0;
  init.totalWorkTimeMinute = 0;
  init.totalWorkTimeString = "00:00";
  init.manHourWithOutPayMinute = 0;

  if (!data) return init;
  let fingerprintList = [];
  //1.validate string type pattern time HH:mm
  for (let i = 1; i <= 6; i++) {
    const fingerprint = data[`fingerprint${i}`];

    if (fingerprint && validateTime(fingerprint)) {
      fingerprintList.push(fingerprint);
    }
  }

  const { importLeave = [] } = init;

  //ตรวจสอบว่าลำดับเวลาก่อนหน้าต้องไม่เป็นค่าว่าง
  const isLevelOk = validateFingerprintsLevel(data);
  if (!isLevelOk && importLeave.length === 0) {
    return init;
  }

  //ลำดับเวลาไม่ถูกต้อง
  if (!validateFingerprintsBeforeTime(fingerprintList)) {
    return init;
  }

  //ตัดเวลาหลัง 17:00 เหลือเพียงรายการเดียว
  let endOfDay = moment("17:00", "HH:mm");
  let beforeEndOfDayList = fingerprintList.filter((fingerprint) => {
    return moment(fingerprint, "HH:mm").isBefore(endOfDay);
  });
  let afterEndOfDayList = fingerprintList.filter((fingerprint) => {
    return moment(fingerprint, "HH:mm").isSameOrAfter(endOfDay);
  });
  if (afterEndOfDayList.length > 0) {
    let minAfterEndOfDay = afterEndOfDayList.reduce((minTime, current) => {
      return moment(current, "HH:mm").isBefore(moment(minTime, "HH:mm"))
        ? current
        : minTime;
    });

    beforeEndOfDayList.push(minAfterEndOfDay);
  }

  if (beforeEndOfDayList.length === 0 || beforeEndOfDayList.length === 1) {
    if (importLeave.length > 0) {
      init = {
        ...init,
        ...calculateLeaveWith0Finger(importLeave),
        from: "calculateLeaveWith0Finger",
      };
    }
  }
  if (beforeEndOfDayList.length === 2) {
    init = {
      ...init,
      ...calculateManHourWith2Finger(
        beforeEndOfDayList[0],
        beforeEndOfDayList[1],
        importLeave
      ),
      from: "calculateManHourWith2Finger",
    };
  }
  if (beforeEndOfDayList.length === 3) {
    init = {
      ...init,
      ...calculateManHourWith3Finger(
        beforeEndOfDayList[0],
        beforeEndOfDayList[1],
        beforeEndOfDayList[2],
        importLeave
      ),
      from: "calculateManHourWith3Finger",
    };
  }
  if (beforeEndOfDayList.length === 4) {
    init = {
      ...init,
      ...calculateManHourWith4Finger(
        beforeEndOfDayList[0],
        beforeEndOfDayList[1],
        beforeEndOfDayList[2],
        beforeEndOfDayList[3],
        importLeave
      ),
      from: "calculateManHourWith4Finger",
    };
  }

  if (beforeEndOfDayList.length === 5) {
    init = {
      ...init,
      ...calculateManHourWith5Finger(
        beforeEndOfDayList[0],
        beforeEndOfDayList[1],
        beforeEndOfDayList[2],
        beforeEndOfDayList[3],
        beforeEndOfDayList[4],
        importLeave
      ),
      from: "calculateManHourWith5Finger",
    };
  }

  if (beforeEndOfDayList.length === 6) {
    init = {
      ...init,
      ...calculateManHourWith6Finger(
        beforeEndOfDayList[0],
        beforeEndOfDayList[1],
        beforeEndOfDayList[2],
        beforeEndOfDayList[3],
        beforeEndOfDayList[4],
        beforeEndOfDayList[5],
        importLeave
      ),
      from: "calculateManHourWith6Finger",
    };
  }
  //คำนวน OT
  let positionIsCanOt = true;
  const positionNotProcessOt = attendanceInit.positionNotProcessOt;
  if (positionNotProcessOt) {
    //ตรวจสอบว่า ตำแหน่งพนักงาน มีการประมวลผล OT หรือไม่
    //ถ้าเจอใน list แปลว่า ไม่สามารถประมวลผล OT ได้
    const findPosition = positionNotProcessOt.find((d) => {
      return d.positionId === init.positionId && d.active === "Y";
    });
    if (findPosition) {
      positionIsCanOt = false;
    }
  }

  console.log("afterEndOfDay", afterEndOfDayList);
  console.log("positionIsCanOt", positionIsCanOt);
  console.log(
    "formatNumber(init?.salaryPosition || 0)",
    formatNumber(init?.salaryPosition || 0)
  );
  if (
    afterEndOfDayList.length > 0 &&
    formatNumber(init?.salaryPosition || 0) < 5000 &&
    positionIsCanOt
  ) {
    init = {
      ...init,
      ...calculateOT(afterEndOfDayList),
      disableOt: false,
    };
  } else {
    init = {
      ...init,
      otMinute: 0,
      otHour: 0,
      otString: "00:00",
      otTotalMinute: 0,
      otTotalString: "00:00",
      disableOt: formatNumber(init?.salaryPosition || 0) < 5000 ? false : true,
      processOt: init.processOt || false,
    };
  }

  //ตรวจสอบการมาสาย
  init.isLateMorning = false;
  init.lateMorningMinute = 0;
  init.isLateAfternoon = false;
  init.lateAfternoonMinute = 0;
  if (
    moment(beforeEndOfDayList[0], "HH:mm").isBefore(moment("12:00", "HH:mm"))
  ) {
    //ตรวจสอบการมาสายเช้า
    if (
      moment(beforeEndOfDayList[0], "HH:mm").isAfter(moment("08:00", "HH:mm"))
    ) {
      init.isLateMorning = true;
      init.lateMorningMinute = moment(beforeEndOfDayList[0], "HH:mm").diff(
        moment("08:00", "HH:mm"),
        "minutes"
      );
    }
  } else {
    //ตรวจสอบการมาสายบ่าย
    if (
      moment(beforeEndOfDayList[0], "HH:mm").isAfter(moment("13:00", "HH:mm"))
    ) {
      init.isLateAfternoon = true;
      init.lateAfternoonMinute = moment(beforeEndOfDayList[0], "HH:mm").diff(
        moment("13:00", "HH:mm"),
        "minutes"
      );
    }
  }
  console.log("MANHOUR RESULT", init);
  return init;
};
function calculateImportLeave(importLeave = []) {
  //with out pay ในความหมายคือ การลาที่ไม่คำนวน manHour
  //manHourProcessMinute ความหมายคือ ต้องการรู้ว่า มีการลงรายละเอียดเวลาครบหรือยัง ทั้งแบบคำนวน ManHour และไม่คำนวนและการขาดงาน
  const results = {
    isFullDay: false,
    manHourMinute: 0,
    leaveHalfDayList: [],
    isFullDayWithOutPay: false,
    manHourWithOutPayMinute: 0,
    leaveHalfDayListWithOutPay: [],
    isFullDayAbsentWork: false,
    manHourAbsentWork: 0,
    absentWorkList: [],
  };

  if (importLeave.length === 0) {
    return results;
  }
  //หารายการขาดงาน
  const findFullAbsentWork = importLeave.find((leave) => {
    return (
      leave.leaveType === "absentWork" &&
      leave.leaveDayType === "full" &&
      leave.status === "Y"
    );
  });
  if (findFullAbsentWork) {
    results.isFullDayAbsentWork = true;
    results.manHourAbsentWork = 60 * 8;
  }

  //คำนวนการลา withOutPay
  const findLeaveWithOutPay = importLeave.find((leave) => {
    return (
      ["leave", "guarantee", "leaveWithOutPay"].includes(leave.leaveType) &&
      leave.leaveDayType === "full" &&
      leave.status === "Y" &&
      leave.processManHour === false
    );
  });
  if (findLeaveWithOutPay) {
    //full day
    results.isFullDayWithOutPay = true;
    results.manHourWithOutPayMinute = 60 * 8;
  }

  //ตรวจสอบการลาตามสิทธิิ แบบเต็มวัน
  // const findFullLeave = importLeave.find(
  //   (leave) =>
  //     leave.leaveType === "leave" &&
  //     leave.leaveDayType === "full" &&
  //     leave.status === "Y"
  // );
  // if (findFullLeave) {
  //   //เงื่อนไขการลาตามสิทธิ์ แบบเต็มวัน
  //   results.manHourMinute = 60 * 8;
  //   results.isFullDay = true;
  //   return results;
  // }
  //ตรวจสอบการรับรองเวลา แบบคำนวน manHour
  const findGuarantee = importLeave.find((leave) => {
    return (
      ["leave", "guarantee", "leaveWithOutPay"].includes(leave.leaveType) &&
      leave.leaveDayType === "full" &&
      leave.status === "Y" &&
      leave.processManHour
    );
  });
  if (findGuarantee) {
    results.isFullDay = true;
    results.manHourMinute = 60 * 8;
    return results;
  }

  //ไม่มีการลาเต็มวัน ตรวจสอบการลาเป็นรายชั่วโมงต่อ
  //กรองเฉพาะรายการลาตามสิทธิ และการรับรองเวลาที่คำนวน manHour ให้
  const filterHalfDay = importLeave.filter((d) => {
    return (
      d.leaveDayType === "half" &&
      d.status === "Y" &&
      ["leave", "guarantee", "leaveWithOutPay"].includes(d.leaveType) &&
      d.processManHour
    );
  });
  results.leaveHalfDayList = filterHalfDay.map((d) => {
    return { startTime: d.startTime, endTime: d.endTime };
  });
  //withOutPay กรองเฉพาะรายการรับรองเวลา และบันทึกการลา ที่ไม่คำนวน manHour
  const filterHalfDayWithOutPay = importLeave.filter((d) => {
    return (
      d.leaveDayType === "half" &&
      d.status === "Y" &&
      ["leave", "guarantee", "leaveWithOutPay"].includes(d.leaveType) &&
      !d.processManHour
    );
  });
  results.leaveHalfDayListWithOutPay = filterHalfDayWithOutPay.map((d) => {
    return { startTime: d.startTime, endTime: d.endTime };
  });
  //absentWork
  const filterAbsentWork = importLeave.filter((d) => {
    return (
      d.leaveType === "absentWork" &&
      d.leaveDayType === "half" &&
      d.status === "Y"
    );
  });
  results.absentWorkList = filterAbsentWork.map((d) => {
    return { startTime: d.startTime, endTime: d.endTime };
  });
  return results;
}

function calculateLeaveWith0Finger(importLeave = []) {
  let manHourMinute = 0;
  let manHourWithOutPayMinute = 0;
  let manHourProcessMinute = 0;
  let totalWorkMinute = 0;
  //การดึงข้อมูลการลา
  let leaveManHourMinute = 0;
  let isLeaveFullDay = false;
  let leaveHalfList = [];
  //withOutPay
  let leaveManHourWithOutPayMinute = 0;
  let isLeaveFullDayWithOutPay = false;
  let leaveHalfListWithOutPay = [];
  //absentWork
  let manHourAbsentWork = 0;
  let isFullDayAbsentWork = false;
  let absentWorkList = [];
  //สำหรับคำนวนทุกรายการ

  if (importLeave.length > 0) {
    const leaveResults = calculateImportLeave(importLeave);
    isLeaveFullDay = leaveResults.isFullDay;
    leaveManHourMinute = leaveResults.manHourMinute;
    leaveHalfList = leaveResults.leaveHalfDayList;

    //withOutPay
    isLeaveFullDayWithOutPay = leaveResults.isFullDayWithOutPay;
    leaveManHourWithOutPayMinute = leaveResults.manHourWithOutPayMinute;
    leaveHalfListWithOutPay = leaveResults.leaveHalfDayListWithOutPay;

    //absentWork
    isFullDayAbsentWork = leaveResults.isFullDayAbsentWork;
    manHourAbsentWork = leaveResults.manHourAbsentWork;
    absentWorkList = leaveResults.absentWorkList;
  }

  if (isLeaveFullDay) {
    manHourMinute = leaveManHourMinute;
  } else if (leaveHalfList.length > 0) {
  }

  if (isLeaveFullDayWithOutPay) {
    manHourWithOutPayMinute = leaveManHourWithOutPayMinute;
  }
  //การคำนวน absentWork
  if (isFullDayAbsentWork) {
    //รายการลาแบบเต็มวัน
    const fullDayMinute =
      manHourAbsentWork > 60 * 8 ? 60 * 8 : manHourAbsentWork;
    manHourAbsentWork = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (absentWorkList.length > 0) {
    //รายการลาแบบครึ่งวัน
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [{ startTime: "08:00", endTime: "08:00" }];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [{ startTime: "12:00", endTime: "13:00" }];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      absentWorkList
    );
    const halfDayMinute =
      manHourAbsentWork + leaveTime > 60 * 8
        ? 60 * 8
        : manHourAbsentWork + leaveTime;
    manHourAbsentWork = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  //การคำนวน withOutPay
  if (isLeaveFullDayWithOutPay) {
    //รายการลาแบบเต็มวัน
    const fullDayMinute =
      leaveManHourWithOutPayMinute > 60 * 8
        ? 60 * 8
        : leaveManHourWithOutPayMinute;
    manHourWithOutPayMinute = fullDayMinute;
    leaveManHourWithOutPayMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfListWithOutPay.length > 0) {
    //รายการลาแบบครึ่งวัน
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [{ startTime: "08:00", endTime: "08:00" }];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [{ startTime: "12:00", endTime: "13:00" }];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfListWithOutPay
    );
    const halfDayMinute =
      manHourWithOutPayMinute + leaveTime > 60 * 8
        ? 60 * 8
        : manHourWithOutPayMinute + leaveTime;
    manHourWithOutPayMinute = halfDayMinute;
    leaveManHourWithOutPayMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  //การคำนวนการลาลง ManHour
  let leaveHourMinute = 0;
  if (isLeaveFullDay) {
    const fullDayMinute =
      leaveManHourMinute > 60 * 8 ? 60 * 8 : leaveManHourMinute;
    manHourMinute = fullDayMinute;
    leaveHourMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [{ startTime: "08:00", endTime: "08:00" }];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [{ startTime: "12:00", endTime: "13:00" }];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfList
    );
    const halfDayMinute =
      manHourMinute + leaveTime > 60 * 8 ? 60 * 8 : manHourMinute + leaveTime;
    manHourMinute = halfDayMinute;
    leaveHourMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  return {
    manHour: Math.floor(manHourMinute / 60),
    manHourMinute: manHourMinute % 60,
    manHourTotalMinute: manHourMinute,
    manHourString: `${String(Math.floor(manHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(manHourMinute % 60).padStart(2, "0")}`,
    manHourWithOutPayMinute: manHourWithOutPayMinute,
    manHourProcessMinute:
      manHourProcessMinute > 480 ? 480 : manHourProcessMinute,
    totalWorkTime: Math.floor(totalWorkMinute / 60),
    totalWorkTimeMinute: totalWorkMinute,
    totalWorkTimeString: `${String(Math.floor(totalWorkMinute / 60)).padStart(
      2,
      "0"
    )}:${String(totalWorkMinute % 60).padStart(2, "0")}`,
    leaveHour: Math.floor(leaveHourMinute / 60),
    leaveHourMinute: leaveHourMinute % 60,
    leaveHourTotalMinute: leaveHourMinute,
    leaveHourString: `${String(Math.floor(leaveHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(leaveHourMinute % 60).padStart(2, "0")}`,
    breakLateMinute: 0,
    isBreakLate: false,
  };
}

function calculateManHourWith2Finger(
  fingerprint1,
  fingerprint2,
  importLeave = []
) {
  if (!validateTime(fingerprint1) || !validateTime(fingerprint2)) {
    return {
      manHour: 0,
      manHourMinute: 0,
      manHourString: "00:00",
      totalWorkTime: 0,
      totalWorkTimeMinute: 0,
      totalWorkTimeString: "00:00",
    };
  }

  let startWorking = moment(fingerprint1, "HH:mm");
  let endWorking = moment(fingerprint2, "HH:mm");

  //การดึงข้อมูลการลา
  let leaveManHourMinute = 0;
  let isLeaveFullDay = false;
  let leaveHalfList = [];
  //withOutPay
  let leaveManHourWithOutPayMinute = 0;
  let isLeaveFullDayWithOutPay = false;
  let leaveHalfListWithOutPay = [];
  //absentWork
  let manHourAbsentWork = 0;
  let isFullDayAbsentWork = false;
  let absentWorkList = [];
  if (importLeave.length > 0) {
    const leaveResults = calculateImportLeave(importLeave);
    isLeaveFullDay = leaveResults.isFullDay;
    leaveManHourMinute = leaveResults.manHourMinute;
    leaveHalfList = leaveResults.leaveHalfDayList;
    //withOutPay
    isLeaveFullDayWithOutPay = leaveResults.isFullDayWithOutPay;
    leaveManHourWithOutPayMinute = leaveResults.manHourWithOutPayMinute;
    leaveHalfListWithOutPay = leaveResults.leaveHalfDayListWithOutPay;
    //absentWork
    isFullDayAbsentWork = leaveResults.isFullDayAbsentWork;
    manHourAbsentWork = leaveResults.manHourAbsentWork;
    absentWorkList = leaveResults.absentWorkList;
  }
  //end การคำนวนการลา

  //ตรวจสอบก่อนว่า ลำดับเวลาถูกต้องหรือไม่ เวลาวางนิ้วลำดับถัดไปต้องมากกว่าเสมอ
  let validateFingerprints = [startWorking, endWorking];
  if (!validateFingerprintsBeforeTime(validateFingerprints)) {
    return wrongValidData;
  }

  let manHourMinute = 0;
  let manHourWithOutPayMinute = 0;
  let manHourProcessMinute = 0;

  let totalWorkMinute = 0;
  let startOfDay = moment("08:00", "HH:mm");
  let lunchStart = moment("12:00", "HH:mm");
  let lunchEnd = moment("13:00", "HH:mm");
  let endOfDay = moment("17:00", "HH:mm");

  if (startWorking.isSameOrAfter(lunchStart)) {
    //เริ่มงานครึ่งวันบ่าย
    totalWorkMinute = endWorking.diff(lunchEnd, "minutes");
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }
    manHourMinute = endWorking.diff(lunchEnd, "minutes");
    manHourProcessMinute += endWorking.diff(lunchEnd, "minutes");
  } else {
    //เริ่มงานช่วงเช้า
    const breakTime = 60;
    totalWorkMinute = endWorking.diff(startWorking, "minutes") - breakTime;
    if (startWorking.isBefore(startOfDay)) {
      startWorking = startOfDay;
    }
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }

    if (endWorking.isBefore(lunchStart)) {
      manHourMinute = endWorking.diff(startWorking, "minutes");
      manHourProcessMinute += endWorking.diff(startWorking, "minutes");
    } else {
      //คำนวน Manhour ครึ่งเช้า
      manHourMinute = lunchStart.diff(startWorking, "minutes");
      //คำนวนครึ่งบ่าย
      const halfAfterNoon = endWorking.diff(lunchEnd, "minutes");
      manHourMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
      manHourProcessMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
    }
  }
  if (isLeaveFullDay) {
    manHourMinute = leaveManHourMinute;
  } else if (leaveHalfList.length > 0) {
  }

  //absentWork
  if (isFullDayAbsentWork) {
    const fullDayMinute =
      manHourAbsentWork > 60 * 8 ? 60 * 8 : manHourAbsentWork;
    manHourAbsentWork = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (absentWorkList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [{ startTime: fingerprint1, endTime: fingerprint2 }];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [{ startTime: "12:00", endTime: "13:00" }];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      absentWorkList
    );
    const halfDayMinute =
      manHourAbsentWork + leaveTime > 60 * 8
        ? 60 * 8
        : manHourAbsentWork + leaveTime;
    manHourAbsentWork = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  //withOutPay
  if (isLeaveFullDayWithOutPay) {
    const fullDayMinute =
      leaveManHourWithOutPayMinute > 60 * 8
        ? 60 * 8
        : leaveManHourWithOutPayMinute;
    manHourWithOutPayMinute = fullDayMinute;
    leaveManHourWithOutPayMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfListWithOutPay.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [{ startTime: fingerprint1, endTime: fingerprint2 }];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [{ startTime: "12:00", endTime: "13:00" }];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfListWithOutPay
    );
    const halfDayMinute =
      manHourWithOutPayMinute + leaveTime > 60 * 8
        ? 60 * 8
        : manHourWithOutPayMinute + leaveTime;
    manHourWithOutPayMinute = halfDayMinute;
    leaveManHourWithOutPayMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  //การคำนวนการลาลง ManHour
  let leaveHourMinute = 0;
  if (isLeaveFullDay) {
    const fullDayMinute =
      leaveManHourMinute > 60 * 8 ? 60 * 8 : leaveManHourMinute;
    manHourMinute = fullDayMinute;
    leaveHourMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [{ startTime: fingerprint1, endTime: fingerprint2 }];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [{ startTime: "12:00", endTime: "13:00" }];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfList
    );
    const halfDayMinute =
      manHourMinute + leaveTime > 60 * 8 ? 60 * 8 : manHourMinute + leaveTime;
    manHourMinute = halfDayMinute;
    leaveHourMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  return {
    manHour: Math.floor(manHourMinute / 60),
    manHourMinute: manHourMinute % 60,
    manHourTotalMinute: manHourMinute,
    manHourString: `${String(Math.floor(manHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(manHourMinute % 60).padStart(2, "0")}`,
    manHourWithOutPayMinute: manHourWithOutPayMinute,
    manHourProcessMinute:
      manHourProcessMinute > 480 ? 480 : manHourProcessMinute,
    totalWorkTime: Math.floor(totalWorkMinute / 60),
    totalWorkTimeMinute: totalWorkMinute,
    totalWorkTimeString: `${String(Math.floor(totalWorkMinute / 60)).padStart(
      2,
      "0"
    )}:${String(totalWorkMinute % 60).padStart(2, "0")}`,
    leaveHour: Math.floor(leaveHourMinute / 60),
    leaveHourMinute: leaveHourMinute % 60,
    leaveHourTotalMinute: leaveHourMinute,
    leaveHourString: `${String(Math.floor(leaveHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(leaveHourMinute % 60).padStart(2, "0")}`,
    breakLateMinute: 0,
    isBreakLate: false,
  };
}

function calculateManHourWith3Finger(
  fingerprint1,
  fingerprint2,
  fingerprint3,
  importLeave = []
) {
  if (
    !validateTime(fingerprint1) ||
    !validateTime(fingerprint2) ||
    !validateTime(fingerprint3)
  ) {
    return {
      manHour: 0,
      manHourMinute: 0,
      manHourString: "00:00",
      totalWorkTime: 0,
      totalWorkTimeMinute: 0,
      totalWorkTimeString: "00:00",
    };
  }

  let startWorking = moment(fingerprint1, "HH:mm");
  let midWorking = moment(fingerprint2, "HH:mm");
  let endWorking = moment(fingerprint3, "HH:mm");

  //การดึงข้อมูลการลา
  let leaveManHourMinute = 0;
  let isLeaveFullDay = false;
  let leaveHalfList = [];
  //withOutPay
  let leaveManHourWithOutPayMinute = 0;
  let isLeaveFullDayWithOutPay = false;
  let leaveHalfListWithOutPay = [];
  //absentWork
  let manHourAbsentWork = 0;
  let isFullDayAbsentWork = false;
  let absentWorkList = [];
  if (importLeave.length > 0) {
    const leaveResults = calculateImportLeave(importLeave);
    isLeaveFullDay = leaveResults.isFullDay;
    leaveManHourMinute = leaveResults.manHourMinute;
    leaveHalfList = leaveResults.leaveHalfDayList;
    //withOutPay
    isLeaveFullDayWithOutPay = leaveResults.isFullDayWithOutPay;
    leaveManHourWithOutPayMinute = leaveResults.manHourWithOutPayMinute;
    leaveHalfListWithOutPay = leaveResults.leaveHalfDayListWithOutPay;
    //absentWork
    isFullDayAbsentWork = leaveResults.isFullDayAbsentWork;
    manHourAbsentWork = leaveResults.manHourAbsentWork;
    absentWorkList = leaveResults.absentWorkList;
  }
  //end การคำนวนการลา

  //ตรวจสอบก่อนว่า ลำดับเวลาถูกต้องหรือไม่ เวลาวางนิ้วลำดับถัดไปต้องมากกว่าเสมอ
  let validateFingerprints = [startWorking, midWorking, endWorking];
  if (!validateFingerprintsBeforeTime(validateFingerprints)) {
    return wrongValidData;
  }

  let manHourMinute = 0;
  let manHourWithOutPayMinute = 0;
  let manHourProcessMinute = 0;
  let totalWorkMinute = 0;

  let startOfDay = moment("08:00", "HH:mm");
  let lunchStart = moment("12:00", "HH:mm");
  let lunchEnd = moment("13:00", "HH:mm");
  let endOfDay = moment("17:00", "HH:mm");

  if (startWorking.isSameOrAfter(lunchStart)) {
    //เริ่มงานครึ่งวันบ่าย หาก manHour หลังจากเวลา 13:30
    totalWorkMinute = endWorking.diff(lunchEnd, "minutes");
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }
    manHourMinute = endWorking.diff(lunchEnd, "minutes");
    manHourProcessMinute += endWorking.diff(lunchEnd, "minutes");
  } else {
    //เริ่มงานช่วงเช้า
    const breakTime = 60;
    totalWorkMinute = endWorking.diff(startWorking, "minutes") - breakTime;
    if (startWorking.isBefore(startOfDay)) {
      startWorking = startOfDay;
    }
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }

    if (endWorking.isBefore(lunchStart)) {
      //เลิกงานก่อน 12:00 พักเที่ยงคิดตามจริง แต่ไม่เกิน 4 ชม
      manHourMinute = endWorking.diff(startWorking, "minutes");
      manHourProcessMinute += endWorking.diff(startWorking, "minutes");
    } else {
      //1.คำนวน Manhour ครึ่งเช้า
      manHourMinute = lunchStart.diff(startWorking, "minutes");
      manHourProcessMinute += lunchStart.diff(startWorking, "minutes");
      //2.คำนวน midtime ว่าอยู่ในช่วงเวลาพักหรือไม่ เทียบกับเวลาเลิกงาน
      if (
        midWorking.isSameOrAfter(lunchStart) &&
        midWorking.isSameOrBefore(endOfDay) &&
        endWorking.diff(midWorking, "minutes") <= 60
      ) {
        //midTime และ endWorking อยู่ในช่วงเวลาพักไม่คิดเวลารอบบ่าย
      } else {
        //คำนวนครึ่งบ่าย
        const halfAfterNoon = endWorking.diff(lunchEnd, "minutes");
        manHourMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
        manHourProcessMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
      }
    }
  }

  //การคำนวนการ absentWork
  if (isFullDayAbsentWork) {
    const fullDayMinute =
      manHourAbsentWork > 60 * 8 ? 60 * 8 : manHourAbsentWork;
    manHourAbsentWork = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (absentWorkList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [{ startTime: fingerprint1, endTime: fingerprint3 }];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [{ startTime: "12:00", endTime: "13:00" }];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      absentWorkList
    );
    const halfDayMinute =
      manHourAbsentWork + leaveTime > 60 * 8
        ? 60 * 8
        : manHourAbsentWork + leaveTime;
    manHourAbsentWork = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  //การคำนวนการลาลง withOutPay
  if (isLeaveFullDayWithOutPay) {
    const fullDayMinute =
      leaveManHourWithOutPayMinute > 60 * 8
        ? 60 * 8
        : leaveManHourWithOutPayMinute;
    manHourWithOutPayMinute = fullDayMinute;
    leaveManHourWithOutPayMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfListWithOutPay.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [{ startTime: fingerprint1, endTime: fingerprint3 }];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [{ startTime: "12:00", endTime: "13:00" }];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfListWithOutPay
    );
    const halfDayMinute =
      manHourWithOutPayMinute + leaveTime > 60 * 8
        ? 60 * 8
        : manHourWithOutPayMinute + leaveTime;
    manHourWithOutPayMinute = halfDayMinute;
    leaveManHourWithOutPayMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  //การคำนวนการลาลง ManHour
  let leaveHourMinute = 0;
  if (isLeaveFullDay) {
    const fullDayMinute =
      leaveManHourMinute > 60 * 8 ? 60 * 8 : leaveManHourMinute;
    manHourMinute = fullDayMinute;
    leaveHourMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [{ startTime: fingerprint1, endTime: fingerprint3 }];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [{ startTime: "12:00", endTime: "13:00" }];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfList
    );
    const halfDayMinute =
      manHourMinute + leaveTime > 60 * 8 ? 60 * 8 : manHourMinute + leaveTime;
    manHourMinute = halfDayMinute;
    leaveHourMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  return {
    manHour: Math.floor(manHourMinute / 60),
    manHourMinute: manHourMinute % 60,
    manHourTotalMinute: manHourMinute,
    manHourString: `${String(Math.floor(manHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(manHourMinute % 60).padStart(2, "0")}`,
    manHourWithOutPayMinute: manHourWithOutPayMinute,
    manHourProcessMinute:
      manHourProcessMinute > 480 ? 480 : manHourProcessMinute,
    totalWorkTime: Math.floor(totalWorkMinute / 60),
    totalWorkTimeMinute: totalWorkMinute,
    totalWorkTimeString: `${String(Math.floor(totalWorkMinute / 60)).padStart(
      2,
      "0"
    )}:${String(totalWorkMinute % 60).padStart(2, "0")}`,
    leaveHour: Math.floor(leaveHourMinute / 60),
    leaveHourMinute: leaveHourMinute % 60,
    leaveHourTotalMinute: leaveHourMinute,
    leaveHourString: `${String(Math.floor(leaveHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(leaveHourMinute % 60).padStart(2, "0")}`,
    breakLateMinute: 0,
    isBreakLate: false,
  };
}

function calculateManHourWith4Finger(
  fingerprint1,
  fingerprint2,
  fingerprint3,
  fingerprint4,
  importLeave = []
) {
  if (
    !validateTime(fingerprint1) ||
    !validateTime(fingerprint2) ||
    !validateTime(fingerprint3) ||
    !validateTime(fingerprint4)
  ) {
    return {
      manHour: 0,
      manHourMinute: 0,
      manHourString: "00:00",
      totalWorkTime: 0,
      totalWorkTimeMinute: 0,
      totalWorkTimeString: "00:00",
    };
  }

  let startWorking = moment(fingerprint1, "HH:mm");
  let rest1 = moment(fingerprint2, "HH:mm");
  let rest2 = moment(fingerprint3, "HH:mm");
  let endWorking = moment(fingerprint4, "HH:mm");

  //การดึงข้อมูลการลา
  let leaveManHourMinute = 0;
  let isLeaveFullDay = false;
  let leaveHalfList = [];
  //withOutPay
  let leaveManHourWithOutPayMinute = 0;
  let isLeaveFullDayWithOutPay = false;
  let leaveHalfListWithOutPay = [];
  //absentWork
  let manHourAbsentWork = 0;
  let isFullDayAbsentWork = false;
  let absentWorkList = [];
  if (importLeave.length > 0) {
    const leaveResults = calculateImportLeave(importLeave);
    isLeaveFullDay = leaveResults.isFullDay;
    leaveManHourMinute = leaveResults.manHourMinute;
    leaveHalfList = leaveResults.leaveHalfDayList;
    //withOutPay
    isLeaveFullDayWithOutPay = leaveResults.isFullDayWithOutPay;
    leaveManHourWithOutPayMinute = leaveResults.manHourWithOutPayMinute;
    leaveHalfListWithOutPay = leaveResults.leaveHalfDayListWithOutPay;
    //absentWork
    isFullDayAbsentWork = leaveResults.isFullDayAbsentWork;
    manHourAbsentWork = leaveResults.manHourAbsentWork;
    absentWorkList = leaveResults.absentWorkList;
  }
  //end การคำนวนการลา

  //ตรวจสอบก่อนว่า ลำดับเวลาถูกต้องหรือไม่ เวลาวางนิ้วลำดับถัดไปต้องมากกว่าเสมอ
  let validateFingerprints = [startWorking, rest1, rest2, endWorking];
  if (!validateFingerprintsBeforeTime(validateFingerprints)) {
    return wrongValidData;
  }

  let manHourMinute = 0;
  let manHourWithOutPayMinute = 0;
  let manHourProcessMinute = 0;
  let totalWorkMinute = 0;
  let startOfDay = moment("08:00", "HH:mm");
  let lunchStart = moment("12:00", "HH:mm");
  let lunchEnd = moment("13:00", "HH:mm");
  let endOfDay = moment("17:00", "HH:mm");
  const breakTime = 60;

  //หาเวลาพักเที่ยงก่อน ว่าเกิน 60 นาที หรือไม่
  const restTimeTotal = rest2.diff(rest1, "minutes");
  const restTimeOverloadMinite =
    restTimeTotal > breakTime ? restTimeTotal - breakTime : 0;

  if (startWorking.isSameOrAfter(lunchStart)) {
    //เริ่มงานครึ่งวันบ่าย
    totalWorkMinute =
      endWorking.diff(lunchEnd, "minutes") - restTimeOverloadMinite;
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }
    manHourMinute =
      endWorking.diff(lunchEnd, "minutes") - restTimeOverloadMinite;
    manHourProcessMinute +=
      endWorking.diff(lunchEnd, "minutes") - restTimeOverloadMinite;
  } else {
    //เริ่มงานช่วงเช้า
    totalWorkMinute =
      endWorking.diff(startWorking, "minutes") - restTimeOverloadMinite;
    if (startWorking.isBefore(startOfDay)) {
      startWorking = startOfDay;
    }
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }

    if (endWorking.isBefore(lunchStart)) {
      //เลิกงานก่อนเที่ยง
      manHourMinute = endWorking.diff(startWorking, "minutes");
      manHourProcessMinute += endWorking.diff(startWorking, "minutes");
    } else {
      //คำนวน Manhour ครึ่งเช้า
      manHourMinute = lunchStart.diff(startWorking, "minutes");
      manHourProcessMinute += lunchStart.diff(startWorking, "minutes");
      //คำนวนครึ่งบ่าย
      const halfAfterNoon = endWorking.diff(lunchEnd, "minutes");
      manHourMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
      manHourProcessMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
      //ลบเวลาพักเที่ยงที่เกิน
      manHourMinute -= restTimeOverloadMinite;
      manHourProcessMinute -= restTimeOverloadMinite;
    }
  }

  //การคำนวนการ absentWork
  if (isFullDayAbsentWork) {
    const fullDayMinute =
      manHourAbsentWork > 60 * 8 ? 60 * 8 : manHourAbsentWork;
    manHourAbsentWork = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (absentWorkList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [
      { startTime: fingerprint1, endTime: fingerprint2 },
      { startTime: fingerprint3, endTime: fingerprint4 },
    ];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [
      { startTime: "12:00", endTime: "13:00" },
      { startTime: fingerprint2, endTime: fingerprint3 },
    ];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      absentWorkList
    );
    const halfDayMinute =
      manHourAbsentWork + leaveTime > 60 * 8
        ? 60 * 8
        : manHourAbsentWork + leaveTime;
    manHourAbsentWork = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }
  //การคำนวนการลาลง withOutPay
  if (isLeaveFullDayWithOutPay) {
    const fullDayMinute =
      leaveManHourWithOutPayMinute > 60 * 8
        ? 60 * 8
        : leaveManHourWithOutPayMinute;
    manHourWithOutPayMinute = fullDayMinute;
    leaveManHourWithOutPayMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfListWithOutPay.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [
      { startTime: fingerprint1, endTime: fingerprint2 },
      { startTime: fingerprint3, endTime: fingerprint4 },
    ];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [
      { startTime: "12:00", endTime: "13:00" },
      { startTime: fingerprint2, endTime: fingerprint3 },
    ];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfListWithOutPay
    );
    const halfDayMinute =
      manHourWithOutPayMinute + leaveTime > 60 * 8
        ? 60 * 8
        : manHourWithOutPayMinute + leaveTime;
    manHourWithOutPayMinute = halfDayMinute;
    leaveManHourWithOutPayMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }
  //การคำนวนการลาลง ManHour
  let leaveHourMinute = 0;
  if (isLeaveFullDay) {
    const fullDayMinute =
      leaveManHourMinute > 60 * 8 ? 60 * 8 : leaveManHourMinute;
    manHourMinute = fullDayMinute;
    leaveHourMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [
      { startTime: fingerprint1, endTime: fingerprint2 },
      { startTime: fingerprint3, endTime: fingerprint4 },
    ];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [
      { startTime: "12:00", endTime: "13:00" },
      { startTime: fingerprint2, endTime: fingerprint3 },
    ];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfList
    );
    const halfDayMinute =
      manHourMinute + leaveTime > 60 * 8 ? 60 * 8 : manHourMinute + leaveTime;
    manHourMinute = halfDayMinute;
    leaveHourMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  return {
    manHour: Math.floor(manHourMinute / 60),
    manHourMinute: manHourMinute % 60,
    manHourTotalMinute: manHourMinute,
    manHourString: `${String(Math.floor(manHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(manHourMinute % 60).padStart(2, "0")}`,
    manHourWithOutPayMinute: manHourWithOutPayMinute,
    manHourProcessMinute:
      manHourProcessMinute > 480 ? 480 : manHourProcessMinute,
    totalWorkTime: Math.floor(totalWorkMinute / 60),
    totalWorkTimeMinute: totalWorkMinute,
    totalWorkTimeString: `${String(Math.floor(totalWorkMinute / 60)).padStart(
      2,
      "0"
    )}:${String(totalWorkMinute % 60).padStart(2, "0")}`,
    leaveHour: Math.floor(leaveHourMinute / 60),
    leaveHourMinute: leaveHourMinute % 60,
    leaveHourTotalMinute: leaveHourMinute,
    leaveHourString: `${String(Math.floor(leaveHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(leaveHourMinute % 60).padStart(2, "0")}`,
    breakLateMinute: restTimeOverloadMinite,
    isBreakLate: restTimeOverloadMinite > 0,
  };
}

function calculateManHourWith5Finger(
  fingerprint1,
  fingerprint2,
  fingerprint3,
  fingerprint4,
  fingerprint5,
  importLeave = []
) {
  if (
    !validateTime(fingerprint1) ||
    !validateTime(fingerprint2) ||
    !validateTime(fingerprint3) ||
    !validateTime(fingerprint4) ||
    !validateTime(fingerprint5)
  ) {
    return {
      manHour: 0,
      manHourMinute: 0,
      manHourString: "00:00",
      totalWorkTime: 0,
      totalWorkTimeMinute: 0,
      totalWorkTimeString: "00:00",
    };
  }

  let startWorking = moment(fingerprint1, "HH:mm");
  let rest1 = moment(fingerprint2, "HH:mm");
  let rest2 = moment(fingerprint3, "HH:mm");
  let rest3 = moment(fingerprint4, "HH:mm");
  let endWorking = moment(fingerprint5, "HH:mm");

  //การดึงข้อมูลการลา
  let leaveManHourMinute = 0;
  let isLeaveFullDay = false;
  let leaveHalfList = [];
  //withOutPay
  let leaveManHourWithOutPayMinute = 0;
  let isLeaveFullDayWithOutPay = false;
  let leaveHalfListWithOutPay = [];
  //absentWork
  let manHourAbsentWork = 0;
  let isFullDayAbsentWork = false;
  let absentWorkList = [];
  if (importLeave.length > 0) {
    const leaveResults = calculateImportLeave(importLeave);
    isLeaveFullDay = leaveResults.isFullDay;
    leaveManHourMinute = leaveResults.manHourMinute;
    leaveHalfList = leaveResults.leaveHalfDayList;
    //withOutPay
    isLeaveFullDayWithOutPay = leaveResults.isFullDayWithOutPay;
    leaveManHourWithOutPayMinute = leaveResults.manHourWithOutPayMinute;
    leaveHalfListWithOutPay = leaveResults.leaveHalfDayListWithOutPay;
    //absentWork
    isFullDayAbsentWork = leaveResults.isFullDayAbsentWork;
    manHourAbsentWork = leaveResults.manHourAbsentWork;
    absentWorkList = leaveResults.absentWorkList;
  }
  //end การคำนวนการลา

  //ตรวจสอบก่อนว่า ลำดับเวลาถูกต้องหรือไม่ เวลาวางนิ้วลำดับถัดไปต้องมากกว่าเสมอ
  let validateFingerprints = [startWorking, rest1, rest2, rest3, endWorking];
  if (!validateFingerprintsBeforeTime(validateFingerprints)) {
    return wrongValidData;
  }

  let manHourMinute = 0;
  let manHourWithOutPayMinute = 0;
  let manHourProcessMinute = 0;
  let totalWorkMinute = 0;
  let startOfDay = moment("08:00", "HH:mm");
  let lunchStart = moment("12:00", "HH:mm");
  let lunchEnd = moment("13:00", "HH:mm");
  let endOfDay = moment("17:00", "HH:mm");
  const breakTime = 60;

  //ประเมินก่อนว่า rest1-3 อันไหนใกล้เคียงเวลาพักมากที่สุด
  let absRest1 = Math.abs(rest1.diff(lunchStart, "minutes"));
  let absRest2 = Math.abs(rest2.diff(lunchStart, "minutes"));
  let minRestDiff = Math.min(absRest1, absRest2);
  let closestRest;
  let closestRest2;
  if (minRestDiff === absRest1) {
    closestRest = rest1;
    closestRest2 = rest2;
  } else {
    if (rest3.isSameOrAfter(endOfDay)) {
      closestRest = rest1;
      closestRest2 = rest2;
    } else {
      closestRest = rest2;
      closestRest2 = rest3;
    }
  }

  const diffRest1 = Math.abs(rest2.diff(rest1, "minutes"));
  const diffRest2 = Math.abs(rest3.diff(rest2, "minutes"));
  const minDiffRest = Math.min(diffRest1, diffRest2);
  if (minDiffRest === diffRest1) {
    closestRest = rest1;
    closestRest2 = rest2;
  } else {
    closestRest = rest2;
    closestRest2 = rest3;
  }

  //หาเวลาพักเที่ยงก่อน ว่าเกิน 60 นาที หรือไม่
  const restTimeTotal = closestRest2.diff(closestRest, "minutes");
  const restTimeOverloadMinite =
    restTimeTotal > breakTime ? restTimeTotal - breakTime : 0;

  if (startWorking.isSameOrAfter(lunchStart)) {
    //เริ่มงานครึ่งวันบ่าย
    totalWorkMinute =
      endWorking.diff(lunchEnd, "minutes") - restTimeOverloadMinite;
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }
    manHourMinute =
      endWorking.diff(lunchEnd, "minutes") - restTimeOverloadMinite;
    manHourProcessMinute +=
      endWorking.diff(lunchEnd, "minutes") - restTimeOverloadMinite;
  } else {
    //เริ่มงานช่วงเช้า
    totalWorkMinute =
      endWorking.diff(startWorking, "minutes") - restTimeOverloadMinite;
    if (startWorking.isBefore(startOfDay)) {
      startWorking = startOfDay;
    }
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }

    if (endWorking.isBefore(lunchStart)) {
      //เลิกงานก่อนเที่ยง
      manHourMinute = endWorking.diff(startWorking, "minutes");
      manHourProcessMinute += endWorking.diff(startWorking, "minutes");
    } else {
      //คำนวน Manhour ครึ่งเช้า
      manHourMinute = lunchStart.diff(startWorking, "minutes");
      manHourProcessMinute += lunchStart.diff(startWorking, "minutes");
      //คำนวนครึ่งบ่าย
      const halfAfterNoon = endWorking.diff(lunchEnd, "minutes");
      manHourMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
      manHourProcessMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
      //ลบเวลาพักเที่ยงที่เกิน
      manHourMinute -= restTimeOverloadMinite;
      manHourProcessMinute -= restTimeOverloadMinite;
    }
  }

  //การคำนวนการ absentWork
  if (isFullDayAbsentWork) {
    const fullDayMinute =
      manHourAbsentWork > 60 * 8 ? 60 * 8 : manHourAbsentWork;
    manHourAbsentWork = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (absentWorkList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [
      { startTime: fingerprint1, endTime: closestRest },
      { startTime: closestRest2, endTime: fingerprint5 },
    ];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [
      { startTime: "12:00", endTime: "13:00" },
      { startTime: closestRest, endTime: closestRest2 },
    ];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      absentWorkList
    );
    const halfDayMinute =
      manHourAbsentWork + leaveTime > 60 * 8
        ? 60 * 8
        : manHourAbsentWork + leaveTime;
    manHourAbsentWork = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }
  //การคำนวนการลาลง withOutPay
  if (isLeaveFullDayWithOutPay) {
    const fullDayMinute =
      leaveManHourWithOutPayMinute > 60 * 8
        ? 60 * 8
        : leaveManHourWithOutPayMinute;
    manHourWithOutPayMinute = fullDayMinute;
    leaveManHourWithOutPayMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfListWithOutPay.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [
      { startTime: fingerprint1, endTime: closestRest },
      { startTime: closestRest2, endTime: fingerprint5 },
    ];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [
      { startTime: "12:00", endTime: "13:00" },
      { startTime: closestRest, endTime: closestRest2 },
    ];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfListWithOutPay
    );
    const halfDayMinute =
      manHourWithOutPayMinute + leaveTime > 60 * 8
        ? 60 * 8
        : manHourWithOutPayMinute + leaveTime;
    manHourWithOutPayMinute = halfDayMinute;
    leaveManHourWithOutPayMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  //การคำนวนการลาลง ManHour
  let leaveHourMinute = 0;
  if (isLeaveFullDay) {
    const fullDayMinute =
      leaveManHourMinute > 60 * 8 ? 60 * 8 : leaveManHourMinute;
    manHourMinute = fullDayMinute;
    leaveHourMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [
      { startTime: fingerprint1, endTime: closestRest },
      { startTime: closestRest2, endTime: fingerprint5 },
    ];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [
      { startTime: "12:00", endTime: "13:00" },
      { startTime: closestRest, endTime: closestRest2 },
    ];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfList
    );
    const halfDayMinute =
      manHourMinute + leaveTime > 60 * 8 ? 60 * 8 : manHourMinute + leaveTime;
    manHourMinute = halfDayMinute;
    leaveHourMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  return {
    manHour: Math.floor(manHourMinute / 60),
    manHourMinute: manHourMinute % 60,
    manHourTotalMinute: manHourMinute,
    manHourString: `${String(Math.floor(manHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(manHourMinute % 60).padStart(2, "0")}`,
    manHourWithOutPayMinute: manHourWithOutPayMinute,
    manHourProcessMinute:
      manHourProcessMinute > 480 ? 480 : manHourProcessMinute,
    totalWorkTime: Math.floor(totalWorkMinute / 60),
    totalWorkTimeMinute: totalWorkMinute,
    totalWorkTimeString: `${String(Math.floor(totalWorkMinute / 60)).padStart(
      2,
      "0"
    )}:${String(totalWorkMinute % 60).padStart(2, "0")}`,
    leaveHour: Math.floor(leaveHourMinute / 60),
    leaveHourMinute: leaveHourMinute % 60,
    leaveHourTotalMinute: leaveHourMinute,
    leaveHourString: `${String(Math.floor(leaveHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(leaveHourMinute % 60).padStart(2, "0")}`,
    breakLateMinute: restTimeOverloadMinite,
    isBreakLate: restTimeOverloadMinite > 0,
  };
}

function calculateManHourWith6Finger(
  fingerprint1,
  fingerprint2,
  fingerprint3,
  fingerprint4,
  fingerprint5,
  fingerprint6,
  importLeave = []
) {
  if (
    !validateTime(fingerprint1) ||
    !validateTime(fingerprint2) ||
    !validateTime(fingerprint3) ||
    !validateTime(fingerprint4) ||
    !validateTime(fingerprint5) ||
    !validateTime(fingerprint6)
  ) {
    return {
      manHour: 0,
      manHourMinute: 0,
      manHourString: "00:00",
      totalWorkTime: 0,
      totalWorkTimeMinute: 0,
      totalWorkTimeString: "00:00",
    };
  }

  let startWorking = moment(fingerprint1, "HH:mm");
  let rest1 = moment(fingerprint2, "HH:mm");
  let rest2 = moment(fingerprint3, "HH:mm");
  let rest3 = moment(fingerprint4, "HH:mm");
  let rest4 = moment(fingerprint5, "HH:mm");
  let endWorking = moment(fingerprint6, "HH:mm");

  //การดึงข้อมูลการลา
  let leaveManHourMinute = 0;
  let isLeaveFullDay = false;
  let leaveHalfList = [];
  //withOutPay
  let leaveManHourWithOutPayMinute = 0;
  let isLeaveFullDayWithOutPay = false;
  let leaveHalfListWithOutPay = [];
  //absentWork
  let manHourAbsentWork = 0;
  let isFullDayAbsentWork = false;
  let absentWorkList = [];
  if (importLeave.length > 0) {
    const leaveResults = calculateImportLeave(importLeave);
    isLeaveFullDay = leaveResults.isFullDay;
    leaveManHourMinute = leaveResults.manHourMinute;
    leaveHalfList = leaveResults.leaveHalfDayList;
    //withOutPay
    isLeaveFullDayWithOutPay = leaveResults.isFullDayWithOutPay;
    leaveManHourWithOutPayMinute = leaveResults.manHourWithOutPayMinute;
    leaveHalfListWithOutPay = leaveResults.leaveHalfDayListWithOutPay;
    //absentWork
    isFullDayAbsentWork = leaveResults.isFullDayAbsentWork;
    manHourAbsentWork = leaveResults.manHourAbsentWork;
    absentWorkList = leaveResults.absentWorkList;
  }
  //end การคำนวนการลา

  //ตรวจสอบก่อนว่า ลำดับเวลาถูกต้องหรือไม่ เวลาวางนิ้วลำดับถัดไปต้องมากกว่าเสมอ
  let validateFingerprints = [
    startWorking,
    rest1,
    rest2,
    rest3,
    rest4,
    endWorking,
  ];
  if (!validateFingerprintsBeforeTime(validateFingerprints)) {
    return wrongValidData;
  }

  let manHourMinute = 0;
  let manHourWithOutPayMinute = 0;
  let manHourProcessMinute = 0;
  let totalWorkMinute = 0;
  let startOfDay = moment("08:00", "HH:mm");
  let lunchStart = moment("12:00", "HH:mm");
  let lunchEnd = moment("13:00", "HH:mm");
  let endOfDay = moment("17:00", "HH:mm");
  const breakTime = 60;

  //ประเมินก่อนว่า rest1 กับ rest3 อันไหนใกล้เคียงเวลาพักมากที่สุด
  let absRest1 = Math.abs(rest1.diff(lunchStart, "minutes"));
  let absRest3 = Math.abs(rest3.diff(lunchStart, "minutes"));
  let minRestDiff = Math.min(absRest1, absRest3);
  let closestRest;
  let closestRest2;
  let unknowTime1;
  let unknowTime2;
  if (minRestDiff === absRest1) {
    closestRest = rest1;
    closestRest2 = rest2;
    unknowTime1 = rest3;
    unknowTime2 = rest4;
  } else {
    closestRest = rest3;
    closestRest2 = rest4;
    unknowTime1 = rest1;
    unknowTime2 = rest2;
  }

  //หาเวลาพักเที่ยงก่อน ว่าเกิน 60 นาที หรือไม่
  const restTimeTotal = closestRest2.diff(closestRest, "minutes");
  const restTimeOverloadMinite =
    restTimeTotal > breakTime ? restTimeTotal - breakTime : 0;
  //เวลาที่ขาดงาน
  const unknowTimeTotal = unknowTime2.diff(unknowTime1, "minutes");

  if (startWorking.isSameOrAfter(lunchStart)) {
    //เริ่มงานครึ่งวันบ่าย
    totalWorkMinute =
      endWorking.diff(lunchEnd, "minutes") -
      restTimeOverloadMinite -
      unknowTimeTotal;
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }
    manHourMinute =
      endWorking.diff(lunchEnd, "minutes") -
      restTimeOverloadMinite -
      unknowTimeTotal;
    manHourProcessMinute +=
      endWorking.diff(lunchEnd, "minutes") -
      restTimeOverloadMinite -
      unknowTimeTotal;
  } else {
    //เริ่มงานช่วงเช้า
    totalWorkMinute =
      endWorking.diff(startWorking, "minutes") -
      restTimeOverloadMinite -
      unknowTimeTotal;
    if (startWorking.isBefore(startOfDay)) {
      startWorking = startOfDay;
    }
    if (endWorking.isAfter(endOfDay)) {
      endWorking = endOfDay;
    }

    if (endWorking.isBefore(lunchStart)) {
      //เลิกงานก่อนเที่ยง
      manHourMinute = endWorking.diff(startWorking, "minutes");
      manHourProcessMinute += endWorking.diff(startWorking, "minutes");
    } else {
      //คำนวน Manhour ครึ่งเช้า
      manHourMinute = lunchStart.diff(startWorking, "minutes");
      manHourProcessMinute += lunchStart.diff(startWorking, "minutes");
      //คำนวนครึ่งบ่าย
      const halfAfterNoon = endWorking.diff(lunchEnd, "minutes");
      manHourMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
      manHourProcessMinute += halfAfterNoon > 0 ? halfAfterNoon : 0;
      //ลบเวลาพักเที่ยงที่เกิน
      manHourMinute -= restTimeOverloadMinite;
      manHourProcessMinute -= restTimeOverloadMinite;
      //ลบเวลาที่ไม่ทราบ
      manHourMinute -= unknowTimeTotal;
      manHourProcessMinute -= unknowTimeTotal;
    }
  }

  //การคำนวนการลาลง withOutPay
  if (isFullDayAbsentWork) {
    const fullDayMinute =
      manHourAbsentWork > 60 * 8 ? 60 * 8 : manHourAbsentWork;
    manHourAbsentWork = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (absentWorkList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [
      { startTime: fingerprint1, endTime: fingerprint2 },
      { startTime: fingerprint3, endTime: fingerprint4 },
      { startTime: fingerprint5, endTime: fingerprint6 },
    ];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [
      { startTime: "12:00", endTime: "13:00" },
      { startTime: closestRest, endTime: closestRest2 },
    ];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      absentWorkList
    );
    const halfDayMinute =
      manHourAbsentWork + leaveTime > 60 * 8
        ? 60 * 8
        : manHourAbsentWork + leaveTime;
    manHourAbsentWork = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  //การคำนวนการลาลง withOutPay
  if (isLeaveFullDay) {
    const fullDayMinute =
      leaveManHourWithOutPayMinute > 60 * 8
        ? 60 * 8
        : leaveManHourWithOutPayMinute;
    manHourWithOutPayMinute = fullDayMinute;
    leaveManHourWithOutPayMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfListWithOutPay.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [
      { startTime: fingerprint1, endTime: fingerprint2 },
      { startTime: fingerprint3, endTime: fingerprint4 },
      { startTime: fingerprint5, endTime: fingerprint6 },
    ];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [
      { startTime: "12:00", endTime: "13:00" },
      { startTime: closestRest, endTime: closestRest2 },
    ];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfListWithOutPay
    );
    const halfDayMinute =
      manHourWithOutPayMinute + leaveTime > 60 * 8
        ? 60 * 8
        : manHourWithOutPayMinute + leaveTime;
    manHourWithOutPayMinute = halfDayMinute;
    leaveManHourWithOutPayMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  //การคำนวนการลาลง ManHour
  let leaveHourMinute = 0;
  if (isLeaveFullDay) {
    const fullDayMinute =
      leaveManHourMinute > 60 * 8 ? 60 * 8 : leaveManHourMinute;
    manHourMinute = fullDayMinute;
    leaveHourMinute = fullDayMinute;
    manHourProcessMinute += fullDayMinute;
  } else if (leaveHalfList.length > 0) {
    //เวลาวางนิ้วทั้งหมดตามเวลาจริง
    const workTimeList = [
      { startTime: fingerprint1, endTime: fingerprint2 },
      { startTime: fingerprint3, endTime: fingerprint4 },
      { startTime: fingerprint5, endTime: fingerprint6 },
    ];
    //ใส่เวลาพักมาตราฐาน 12:00-13:00 และ เวลาพักจริง
    const restTimeList = [
      { startTime: "12:00", endTime: "13:00" },
      { startTime: closestRest, endTime: closestRest2 },
    ];
    //leaveHalfList คือเวลาที่ลาทั้งหมดแบบครึ่งวัน
    const leaveTime = calculateTimeDifference(
      workTimeList,
      restTimeList,
      leaveHalfList
    );
    const halfDayMinute =
      manHourMinute + leaveTime > 60 * 8 ? 60 * 8 : manHourMinute + leaveTime;
    manHourMinute = halfDayMinute;
    leaveHourMinute = halfDayMinute;
    manHourProcessMinute += halfDayMinute;
  }

  return {
    manHour: Math.floor(manHourMinute / 60),
    manHourMinute: manHourMinute % 60,
    manHourTotalMinute: manHourMinute,
    manHourString: `${String(Math.floor(manHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(manHourMinute % 60).padStart(2, "0")}`,
    manHourWithOutPayMinute: manHourWithOutPayMinute,
    manHourProcessMinute:
      manHourProcessMinute > 480 ? 480 : manHourProcessMinute,
    totalWorkTime: Math.floor(totalWorkMinute / 60),
    totalWorkTimeMinute: totalWorkMinute,
    totalWorkTimeString: `${String(Math.floor(totalWorkMinute / 60)).padStart(
      2,
      "0"
    )}:${String(totalWorkMinute % 60).padStart(2, "0")}`,
    leaveHour: Math.floor(leaveHourMinute / 60),
    leaveHourMinute: leaveHourMinute % 60,
    leaveHourTotalMinute: leaveHourMinute,
    leaveHourString: `${String(Math.floor(leaveHourMinute / 60)).padStart(
      2,
      "0"
    )}:${String(leaveHourMinute % 60).padStart(2, "0")}`,
    breakLateMinute: restTimeOverloadMinite,
    isBreakLate: restTimeOverloadMinite > 0,
  };
}

function calculateOT(times = []) {
  let timesInit = ["17:00", ...times];
  const timesInMinutes = timesInit.map((time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  });

  const minTimeInMinutes = Math.min(...timesInMinutes);
  const maxTimeInMinutes = Math.max(...timesInMinutes);

  // เปลี่ยนเวลาจากนาทีให้กลับไปเป็นรูปแบบ HH:MM
  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  };

  // คำนวณจำนวนชั่วโมงเต็มโดยไม่นับเศษนาที
  const durationInMinutes = maxTimeInMinutes - minTimeInMinutes;
  const fullHours = Math.floor(durationInMinutes / 60);

  return {
    otMinute: fullHours * 60,
    otHour: fullHours,
    otString: formatTime(fullHours * 60),
    otTotalMinute: durationInMinutes,
    otTotalString: formatTime(durationInMinutes),
  };
}

function calculateTimeDifference(workTime, restTime, leaveTime) {
  const startOfDay = moment("08:00", "HH:mm");
  const endOfDay = moment("17:00", "HH:mm");

  function isTimeInRange(time, timeRanges) {
    return timeRanges.some((range) => {
      const start = moment(range.startTime, "HH:mm");
      const end = moment(range.endTime, "HH:mm");
      return moment(time, "HH:mm").isBetween(start, end, null, "[)");
    });
  }

  // คำนวณหาช่วงเวลาที่มากที่สุดใน restTime เป็นนาที
  const totalRestMinutes = restTime.reduce((max, range) => {
    const start = moment(range.startTime, "HH:mm");
    const end = moment(range.endTime, "HH:mm");
    const duration = moment.duration(end.diff(start)).asMinutes();
    return Math.max(max, duration);
  }, 0);

  let totalMinutes = 0;

  leaveTime.forEach((leave) => {
    let leaveStart = moment.max(moment(leave.startTime, "HH:mm"), startOfDay);
    let leaveEnd = moment.min(moment(leave.endTime, "HH:mm"), endOfDay);

    while (leaveStart.isBefore(leaveEnd)) {
      const currentTime = leaveStart.format("HH:mm");
      if (
        !isTimeInRange(currentTime, workTime) &&
        !isTimeInRange(currentTime, restTime)
      ) {
        totalMinutes++;
      } else if (
        totalRestMinutes > 60 &&
        isTimeInRange(currentTime, restTime)
      ) {
        totalMinutes++;
      }
      //ไล่เวลาไปทีละนาที
      leaveStart.add(1, "minute");
    }
  });

  return totalMinutes;
}

function validateTime(time) {
  const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return regex.test(time);
}

function validateFingerprintsBeforeTime(fingerprints) {
  for (let i = 1; i < fingerprints.length; i++) {
    for (let j = 0; j < i; j++) {
      if (
        moment(fingerprints[i], "HH:mm").isBefore(
          moment(fingerprints[j], "HH:mm")
        )
      ) {
        return false;
      }
    }
  }
  return true;
}

const validateFingerprintsLevel = (data) => {
  // ตรวจสอบ fingerprint1 เป็นอย่างแรก
  if (!data.fingerprint1 || data.fingerprint1.length !== 5) {
    return false;
  }

  for (let i = 2; i <= 6; i++) {
    // เริ่มจาก fingerprint2 เนื่องจาก fingerprint1 ตรวจสอบแล้ว
    const fingerprint = data[`fingerprint${i}`];

    // ถ้า fingerprint ปัจจุบันมีค่า แต่ fingerprint ก่อนหน้าเป็นค่าว่างหรือความยาวไม่ถูกต้อง
    if (
      fingerprint &&
      (!data[`fingerprint${i - 1}`] || data[`fingerprint${i - 1}`].length !== 5)
    ) {
      return false;
    }
  }
  return true; // ถ้าไม่มีกรณีที่ผิดพลาด
};
