import aapLogo from "../../Asset/Images/AAP.jpg";
import aasLogo from "../../Asset/Images/AAS.jpg";
import amdLogo from "../../Asset/Images/AMD.jpg";
import armLogo from "../../Asset/Images/ARM.jpg";
import amgLogo from "../../Asset/Images/AMG.jpg";
import amtLogo from "../../Asset/Images/AMT.png";
import aabLogo from "../../Asset/Images/AAB.png";
import aatLogo from "../../Asset/Images/AAT.png";

import logoK from "../../Asset/Images/Attendance/logo_k.png";
import logoG from "../../Asset/Images/Attendance/logo_g.png";
import logoM from "../../Asset/Images/Attendance/logo_m.png";
import moment from "moment";

export const getLogoEnterprice = (enterprise) => {
  switch (enterprise) {
    case "AAP":
      return aapLogo;
    case "AAS":
      return aasLogo;
    case "AMD":
      return amdLogo;
    case "ARM":
      return armLogo;
    case "AMG":
      return amgLogo;
    case "AMT":
      return amtLogo;
    case "AAB":
      return aabLogo;
    case "AAT":
      return aatLogo;
    default:
      return aapLogo;
  }
};

export const getLogoEnterpriceForAttendanceProbation = (enterprise) => {
  console.log("getLogoEnterpriceForAttendanceProbation", enterprise);
  switch (enterprise) {
    case "gW5xpXoQCGtdSdWCW7V3":
    case "hUAUAcbwN8bgVaoRLYQr":
    case "T1ZLvjbmaOIYS41OlWss":
    case "UV6JquA3VKA1zRsbbwoE":
    case "EHatRfWHt8g2FdYMRgMq":
    case "p6U8itK9Tdc2qk6DEAWh":
    case "iISxmcShYrMvy0Vz6n9G":
    case "v9lVkOShI8xgBRfiY6Q1":
    case "XFlgytMscE31IXQ4MLkG":
    case "wqvu29nNmU9xkGdrUauI":
    case "FOJmxBRKM25uXneWRXeP":
    case "gBZxdOlFsqw8cys3elTq":
    case "y7JYrBJbiieY0NYiewve":
    case "HZvoyUwgMyw1bm0TtxnU":
      return logoM;
    case "6VkUYRP9YxXgoFE1FU2d":
    case "E16XP49caF0xjGIXDAcp":
    case "U8oYUy7TVPtSKzlAQvPn":
    case "wjHAb8EDYly1JyU2dls0":
      return logoK;
    case "2mw7zUw09MLFvFqivYME":
    case "GXyVyeiueGm7iPx8hv1d":
      return logoG;
    default:
      return logoM;
  }
};

export const convertToThaiDate = (dateString) => {
  const monthNames = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  let parts = dateString.split("/");
  let day = parts[0];
  let month = monthNames[parseInt(parts[1]) - 1];
  let year = parseInt(parts[2]);

  // ตรวจสอบว่าเป็นปีคริสศักราชหรือปีพุทธศักราช
  const currentYear = new Date().getFullYear();
  if (year < currentYear + 543 - 100) {
    // ตั้งเป็น 100 ปีก่อนปีปัจจุบัน
    year += 543;
  }

  return `${day} ${month} ${year}`;
};

export const getThaiMonth = (dateString) => {
  const monthNames = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  let parts = dateString.split("/");
  let month = monthNames[parseInt(parts[1]) - 1];
  let year = parseInt(parts[2]);

  // ตรวจสอบว่าเป็นปีคริสศักราชหรือปีพุทธศักราช
  const currentYear = new Date().getFullYear();
  if (year < currentYear + 543 - 100) {
    // ตั้งเป็น 100 ปีก่อนปีปัจจุบัน
    year += 543;
  }

  return `${month}`;
};

export const convertEnterpriseFullName = (enterprise) => {
  switch (enterprise) {
    case "AAP":
      return "บริษัท อารีมิตร ออโต้เพ้นท์ จำกัด";
    case "AAS":
      return "บริษัท อารีมิตร ออโตเซลส์ จำกัด";
    case "AMD":
      return "บริษัท อารีมิตร มาสด้า จำกัด";
    case "ARM":
      return "บริษัท อารีมิตร ยนตรการ จำกัด";
    case "AMG":
      return "บริษัท อารีมิตร เอ็มจี จำกัด";
    case "AMT":
      return "บริษัท อารีมิตร เกรท วอลล์ จำกัด";
    case "AAB":
      return "บริษัท อารีมิตร ออโตโมบิล จำกัด";
    case "AAT":
      return "บริษัท อารีมิตร ออโต้ โมทีฟ จำกัด";
    default:
      return "";
  }
};

//   function checkEnterprise(enterprise, branch) {
//     switch (enterprise) {
//       case "AAP":
//         return "อารีมิตร ออโต้เพนท์";
//       case "AAS":
//         return "อารีมิตร ออโตเซลส์";
//       case "ARM":
//         return "อารีมิตร ยนตรการ";
//       case "AMG":
//         return "อารีมิตร เอ็มจี";
//       case "AMD":
//         return branch === "AMD1 - สาขากาฬสินธุ์"
//           ? "อารีมิตร มาสด้า สาขากาฬสินธ์ุ"
//           : "อารีมิตร มาสด้า สาขามหาสารคาม";
//       case "AMT":
//         return "อารีมิตร GWM";
//     }
//   }

export const formatTimeText = (minutes) => {
  // Convert minutes to seconds
  const seconds = minutes * 60;

  // Calculate the duration using moment.js
  const duration = moment.duration(seconds, "seconds");

  // Calculate days considering 8 hours as one day
  const totalHours = duration.asHours();
  const days = Math.floor(totalHours / 8);
  const hours = Math.floor(totalHours % 8);
  const minutesPart = duration.minutes();

  // Format the time
  const formattedTime = `${String(days)} วัน | ${String(hours).padStart(
    2,
    "0"
  )}:${String(minutesPart).padStart(2, "0")} ชม.`;

  return formattedTime;
};

export const formatTimeTextShort = (minutes) => {
  // Convert minutes to seconds
  const seconds = minutes * 60;

  // Calculate the duration using moment.js
  const duration = moment.duration(seconds, "seconds");

  // Calculate days considering 8 hours as one day
  const totalHours = duration.asHours();
  const days = Math.floor(totalHours / 8);
  const hours = Math.floor(totalHours % 8);
  const minutesPart = duration.minutes();

  // Format the time
  const formattedTime = `${String(days)} | ${String(hours).padStart(
    2,
    "0"
  )}:${String(minutesPart).padStart(2, "0")}`;

  return formattedTime;
};
