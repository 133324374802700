export const menuEmployeeData = [
  { value: "userViewSalary", label: "ไม่แสดงข้อมูลรายได้" },
  { value: "userDelete", label: "ลบข้อมูลพนักงาน" },
];

export const menuSlipPaymentData = [
  { value: "userViewSlipPayment", label: "อัพโหลดสลิปเงินเดือน" },
];

export const menuReportData = [
  { value: "userViewSalaryReport", label: "รายงานสลิปเงินเดือน" },
  { value: "userViewPaymentReport", label: "รายงานเงินโอนเข้าธนาคาร" },
  {
    value: "userViewCurrentEmployeeReport",
    label: "รายงานรายชื่อพนักงานปัจจุบัน (มีข้อมูลรายได้)",
  },
  { value: "userViewCurrentWorkReport", label: "รายงานข้อมูลการทำงานพนักงาน" },
  {
    value: "userViewAllEmployeeReport",
    label: "รายงานข้อมูลการทำงานและข้อมูลส่วนตัว",
  },
  { value: "userViewLoginReport", label: "รายงาน การเข้าใช้งานพนักงาน" },
  { value: "userIncomeDepositReport", label: "รายงาน รายรับ&รายจ่าย" },
];

export const menuHrmData = [
  { value: "userCreateEvent", label: "งานกิจกรรมบริษัท" },
  { value: "userCreatePublicPost", label: "สร้างประกาศรวม" },
  {
    value: "userCreatePostEmployee",
    label: "สร้างเอกสารพนักงานรายบุคคล (มีข้อมูลรายได้)",
  },
];

export const menuBonusData = [
  { value: "userViewBonus", label: "งานปรับค่าจ้าง และโบนัส" },
];

export const menuSettingData = [
  { value: "userSetting", label: "ตั้งค่าข้อมูล" },
];

export const menuTimeAttendanceData = [
  {
    value: "userManageTimeAttendance",
    label: "ข้อมูลการเข้างาน (Time Attendance)",
  },
  {
    value: "userImportTimeAttendance",
    label: "นำเข้าข้อมูลการเข้างาน (Time Attendance)",
  },
  { value: "userSettingLeave", label: "ตั้งค่าสิทธิการลา (Time Attendance)" },
  { value: "userManageLeave", label: "บันทึกการลาพนักงาน (Time Attendance)" },
  {
    value: "reportTimeAttendanceDaily",
    label: "รายงานสรุป Attendance ประจำวัน (Time Attendance)",
  },
  {
    value: "reportTimeAttendanceDailyMonth",
    label: "รายงาน Attendance ประจำเดือน (Time Attendance)",
  },
  {
    value: "reportTimeAttendanceLeave",
    label: "รายงาน Attendance รายละเอียดการลา (Time Attendance)",
  },
  {
    value: "reportTimeAttendanceManPower",
    label: "รายงาน Man Power Report แยกตาม ตำแหน่ง (Time Attendance)",
  },
  {
    value: "reportTimeAttendanceManPowerMonth",
    label: "รายงาน Man Power Monthly (Time Attendance)",
  },
  {
    value: "reportTimeAttendanceHolidayEmployee",
    label: "รายงานวันลาพักร้อนประจำปี (Time Attendance)",
  },
  {
    value: "reportTimeAttendanceHolidayEmployeeWithSalary",
    label: "รายงานวันลาพักร้อนประจำปี (Time Attendance) (มีข้อมูลรายได้)",
  },
  {
    value: "reportTimeAttendanceQuizEmployeeMonth",
    label: "แบบประเมินผลการ ปฏิบัติงาน (ประจำเดือน) (Time Attendance)",
  },
  {
    value: "reportTimeAttendanceQuizEmployeeTrial",
    label: "แบบประเมินผลการ ปฏิบัติทดลองงาน (Time Attendance)",
  },
];
